<template>
  <div class="task-page">
    <div class="task-top" :class="i18n.locale != 'zh-CN' && i18n.locale != 'zh-TW' ? 'small' : ''">
      <div class="title">{{i18n.t('task.title1')}}</div>
      <div class="title">{{i18n.t('task.title2')}}</div>
<!--      <div class="title">{{i18n.t('task.title3')}}</div>-->
    </div>

    <div class="notice">
      <NoticeBar  left-icon="volume-o"  :text="appName + ' ' + noticeMsg"></NoticeBar>
    </div>

    <div class="task-box flex-center">
      <div class="img-box flex-center" v-show="!loading">
        <img :src="logo" alt="">
      </div>
      <div class="img-box flex-center" v-if="loading">
        <Swipe autoplay="400" :show-indicators="false" class="goods-swipe">
          <SwipeItem v-for="i in 3" :key="i">
            <div class="img-outer" :style="`background: url(${i == 1 ? goodsUrl1 : (i == 2 ? goodsUrl2 : goodsUrl3)}) center center / cover no-repeat`">
            </div>
          </SwipeItem>
        </Swipe>
      </div>
      <div class="flex-1 info-box" v-show="!loading">
        <div :class="' vip leave' + levelInfo.Leave">
          <span >VIP{{levelInfo.Leave}}</span>
        </div>
        <div class="name">{{userName}}</div>
        <div class="info">{{i18n.t('task.label1')}}({{levelInfo.CommissionRate}}%)</div>
        <div>
          <Button @click="submit">{{i18n.t('task.label2')}}</Button>
        </div>
      </div>
      <div class="loading-box flex-1" v-show="loading">
        <div class="loading-title">{{i18n.t('task.label3')}}</div>
        <div>
          <img :src="loadingUrl" alt="">
        </div>
      </div>
    </div>

    <div class="account-box">
      <div class="flex-center-between">
        <div class="flex-center money">
          <span>{{i18n.t('task.label4')}}</span>
          <img :src="refreshUrl" alt="" @click="init">
        </div>
        <div class="link">
          <span @click="toPath('recharge')">{{i18n.t('task.label5')}}</span>
          <span @click="toPath('withdraw')">{{i18n.t('task.label6')}}</span>
        </div>
      </div>
      <div class="flex-center-between role-line">
        <div class="flex-end-start">
          <span class="label">$</span>
          <span class="count">{{parseFloat(userMoney).toFixed(2)}}</span>
        </div>
        <div class="role" @click="showRoleModal">{{i18n.t('task.label7')}}</div>
      </div>

      <div class="tab-list flex">
        <div class="tab flex-1 flex-column-center">
          <div class="count">${{parseFloat(commissionInfo.TodayCommission || 0).toFixed(2)}}</div>
          <div class="label">{{i18n.t('task.label8')}}</div>
        </div>
        <div class="tab flex-1 flex-column-center">
          <div class="count">{{commissionInfo.FinishCount || 0}}</div>
          <div class="label">{{i18n.t('task.label9')}}</div>
        </div>
        <div class="tab flex-1 flex-column-center">
          <div class="count">{{getSurplus()}}</div>
          <div class="label">{{i18n.t('task.label10')}}</div>
        </div>
      </div>
    </div>

    <div class="rank-box flex-center">
      <div class="icon">
        <img :src="rankUrl" alt="">
      </div>
      <div class="flex-column-center flex-1 notice-box">
        <div class="title">{{i18n.t('task.label11')}}</div>
        <div class="notice-line ">
          <Swipe vertical class="notice-swipe" :show-indicators="false"  autoplay="3000">
            <SwipeItem v-for="(item, i) in noticeList" :key="i">
              <div class="flex-center-start">
                {{i18n.t('task.label12')}}[{{item.Name}}]{{i18n.t('task.label13')}}${{item.Price}}
              </div>
            </SwipeItem>
          </Swipe>
        </div>
      </div>
    </div>

     <Popup v-model:show="showModal" class="popup-bg">
      <div class="order-popup">
        <div class="list">
          <div class="flex-center-between">
            <div class="info">
              <div>{{i18n.t('task.label14')}}: {{goodsDetail.CreatedAt}}</div>
              <div>{{i18n.t('task.label15')}}: {{goodsDetail.OrderSn}}</div>
            </div>
            <div class="status" :class="`status-2`"></div>
          </div>
          <div class="goods-info flex-center-between">
            <div class="icon">
              <img :src="getUrl(goodsDetail.Goods.ImgUrl)" alt="">
            </div>
            <div class="flex-column-center-between detail">
              <div class="title">{{goodsDetail.Goods.GoodsName}}</div>
              <div class="flex-center-between money">
                <div>${{parseFloat(goodsDetail.Price).toFixed(2)}}</div>
                <div>X 1</div>
              </div>
            </div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('task.label16')}}</div>
            <div class="count">${{parseFloat(goodsDetail.Price).toFixed(2)}}</div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('task.label17')}}</div>
            <div class="count">${{parseFloat(goodsDetail.EarnCommission).toFixed(2)}}</div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('task.label18')}}</div>
            <div class="count green">${{parseFloat(goodsDetail.Price + goodsDetail.EarnCommission).toFixed(2)}}</div>
          </div>
        </div>
        <div class="btn flex-center">
          <Button class="yellow" @click="cancel">{{i18n.t('task.btn1')}}</Button>
          <Button class="blue" @click="submitOrder">{{i18n.t('task.btn2')}}</Button>
        </div>
      </div>
    </Popup>

    <Popup v-model:show="showRole" class="popup-bg">
      <div class="role-box">
        <div class="line" v-for="(item,i) in MatchRule[MatchRuleIndex]" key="i">{{i+1}}、{{item}}</div>
        <div class="text-center">
          <Button @click="cancel">{{i18n.t('task.btn3')}}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { NoticeBar, Swipe, SwipeItem, Popup  } from 'vant'
import { userInfo } from '@/api/user'
import { createOrder, taskSubmit } from '@/api/task'
import {getCommissionInfo, getMatchRule, getNoticeList} from '@/api/index'
export default {
  components: {
    NoticeBar,
    Swipe,
    SwipeItem,
    Popup
  },
  data() {
    return {
      noticeMsg: this.i18n.t('task.notice'),
      goodsUrl: "",
      goodsError: require('../../assets/imgs/goods.webp'),
      goodsUrl1: require('../../assets/imgs/goods.jpg'),
      goodsUrl2: require('../../assets/imgs/goods2.jpg'),
      goodsUrl3: require('../../assets/imgs/goods3.jpg'),
      refreshUrl: require('../../assets/imgs/icon-refresh.jpg'),
      rankUrl: require('../../assets/imgs/icon-rank.png'),
      loadingUrl: require('../../assets/imgs/loading.svg'),
      userName: '',
      userMoney: '0',
      PlanCount: 0,
      levelName: '',
      MatchRuleIndex: 0,
      MatchRule:[],
      commissionRate: 0,
      vip:[
        require("../../assets/imgs/bg/vip1.png"),
        require("../../assets/imgs/bg/vip2.png"),
        require("../../assets/imgs/bg/vip3.png"),
        require("../../assets/imgs/bg/vip4.png"),
        require("../../assets/imgs/bg/vip5.png"),
        require("../../assets/imgs/bg/vip6.png"),
      ],
      commissionInfo: {},
      goodsDetail: {
        Goods: {}
      },
      levelInfo: {},
      noticeList: [],
      loading: false,
      showModal: false,
      showRole: false,
      timer: null,
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    logo() {
      return this.$store.state.logo
    },
  },
  created() {
    this.MatchRule = [
      [
        this.appName + this.i18n.t('task.desc5'),
        this.$i18n.t('task.desc6'),
        this.i18n.t('task.desc7') + this.appName + this.i18n.t('task.desc7_1'),
        this.i18n.t('task.desc8'),
      ],
      [
        this.appName + this.i18n.t('task.desc5'),
        this.$i18n.t('task.desc6'),
        this.i18n.t('task.desc7') + this.appName + this.i18n.t('task.desc7_1'),
        this.i18n.t('task.desc9'),
      ]
    ]
  },
  mounted() {
    if(this.$store.state.logo.length < 14) {
      this.goodsUrl = this.goodsError
    } else {
      this.goodsUrl = this.$store.state.logo
    }
    console.log("this.goodsUrl")
    console.log(this.goodsUrl)
    this.init()
    this.startTimer()
  },
  beforeRouteLeave(to, from, next) {
    this.destroyTimer();
    next();
  },
  methods: {
    handleImageError() {
      this.goodsUrl = this.goodsError; // 图片加载错误时，将显示指定的错误图片
    },
    getUrl(str){
      if(str === undefined) {
        return
      }
      // 检查是否包含http
      if (str.toString().indexOf("http") === -1) {
        str = "/upload/" + str
      }
      return str
    },
    startTimer() {
      this.timer = setInterval(() => {
        getCommissionInfo().then(res => {
          this.commissionInfo = res.data
        })
      }, 2000);
    },
    destroyTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 计算剩余订单
    getSurplus() {
      let total = this.levelInfo.Num && this.commissionInfo.FinishCount !== undefined ? this.levelInfo.Num : 0
      if(this.PlanCount > 0 ) {
        total = this.PlanCount
      }
      let surplus = total - this.commissionInfo.FinishCount
      return surplus > 0 ? surplus : 0
    },
    getUserInfo() {
      console.log("getUserInfo")
      userInfo().then(res => {
        this.userName = res.data.Nickname
        this.PlanCount = parseInt(res.data.PlanCount,10)
        this.userMoney = res.data.UserAccount.Balance
        this.levelInfo = res.data.Leave
      })
    },
    init() {
      this.getUserInfo()
      getMatchRule().then(res => {
        this.MatchRuleIndex = res.data
      })
      // 获取规则
      getCommissionInfo().then(res => {
        this.commissionInfo = res.data
      })

      getNoticeList().then(res => {
        this.noticeList = res.data.records
      })
    },
    submit() {
      this.loading = true
      setTimeout(() => {
        createOrder().then(res => {
          this.getUserInfo()
          this.loading = false
          if (res.code == 0) {
              let data = res.data
              if (data.length > 0) {
                this.goodsDetail = data[0]
                console.log("this.goodsDetail")
                console.log(this.goodsDetail)
                this.showModal = true
              } else {
                this.$toast(this.i18n.t('task.error'))
              }
            } else {
              this.$toast(res.msg)
            }
        })
      }, 2000)
    },
    cancel() {
      this.showModal = false
      this.showRole = false
    },
    submitOrder() {
      let param = {
        Id: this.goodsDetail.ID
      }
      taskSubmit(param).then(res => {
        this.$toast(res.msg)
        console.log("taskSubmit")
        if(res.code == 0) {
          this.showModal = false
        }
        // this.page.page = 1
        this.init()
      })
    },
    showRoleModal() {
      this.showRole = true
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    }
  }
}
</script>